.topHeader {
  background-color: #FFF;
  padding: 0 10%;
  line-height: 75px;
  color: #05060F;
  font-size: 25px;
  margin-top: -35px;
}

.onlineEditor{
  height:auto;
  background: #fff3a3;
  padding-bottom: 60px;
  padding-top: 20px;
}

.exercise{
  height:500px;
  background: #fff3a3;
  padding-bottom: 60px;
  padding-top: 20px;
}

.exercise .aspirant-learn-button{
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  font-size: 28px;
  text-decoration: none;
}

.footerSection{
  background: rgb(8, 6, 18);
  color: rgb(255, 255, 255);
  padding-top: 100px;
  padding-bottom: 70px;
  padding-left: 50px;
  padding-right: 50px;
}

.eachSubject{
  height: 200px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 45%;
}

.eachSubject.left{
  margin-right: 5%;
}

.eachSubject.right{
  margin-left: 5%;
}

.colorNames div {
  height: 75px;
  align-content: center;
}

.aspirant-learn-button:hover, .techSkillContainer:hover{
  /* background-color: crimson; */
    transition: all 1s;
    /* border-radius: 50%; */
    box-shadow: inset 0 0 20px 20px rgb(109 109 175);
    cursor: pointer;
}

[contenteditable]:empty::before {
  content: attr(data-placeholder);
  color: #999;
  pointer-events: none;
}

.aspirant-bar svg{
  /* margin: 10px 20px; */
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
}

.editorBoard{
  color: mediumblue/*#997*/;
}

.html-tag {
  color: red;
}
.html-attribute {
  color: blue;
}
.html-attribute-value {
  color: green;
}

.runButton{
  /* margin-top: -36px !important; */
  margin-left: 20px;
}


.newUpdate{
  border: 2px solid #CCC;
  padding: 40px;
  box-shadow: -12px 12px 0px rgb(8, 6, 18);
}

.noDecor a{
  color: black;
}

.techSkillContainer{
  padding: 10px;
  margin-right: 25px;
  width: 100%;
  height: 100px;
  border-radius: 25px;
  background: rgb(175 175 217);
  color: rgb(8, 6, 18);
  position: relative;
  border: 1px solid rgb(175 175 217);
  display: flex;
  justify-content: center;
}

.techSkillContainer .techSkill{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-decoration: none;
  color: inherit;
  margin: 0;
  font-weight: 400;
  margin-left: 10px;
  font-size: 22px;
  text-align: center;
}

.homePage p{
  font-size: 20px;
}

.aboutUs .codeh3{
  margin-bottom: 0px;
  padding: 0 60px;
}

.aboutUs p{
  margin-bottom: 0px;
    padding: 0 100px;
    text-align: center;
    padding-bottom: 60px !important;
}

.text-accent-700{
  color: #4848B2;
}

.borderTable{
  text-align: center;
}

.topHeader-2 {
  background-color: #080612;
  padding: 0 10%;
  line-height: 45px;
  color: #FFF;
  font-size: 15px;
  height: 47px;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  margin-top: -35px;
}

h5{
  font-size: 27px !important;
}

h3{
  font-size: 2.25rem !important;
}

.topHeader-2::-webkit-scrollbar,
.my-menu-dropdown::-webkit-scrollbar {
  display: none;
}

.my-menu-dropdown button,
.my-menu-dropdown button:hover,
.my-menu-dropdown button:focus {
  background: transparent;
  border: 0;
}

.my-menu-dropdown li button {
  line-height: normal;
}

.language-menu .my-menu-dropdown {
  height: 45px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.myLink {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.myrow {
  padding: 30px;
}

.columnEach {
  border: 1px solid rgb(33, 33, 133);
  margin: 30px;
  padding: 10px;
  border-radius: 20px;
}

.menuColumn {
  /* background-color: #e9e1e1; */
  background-color: #c8c8ea;
  padding-right: 0;
  /* min-height: 100vh; */
}

.menuColumn ul {
  list-style: none;
  padding: 0;
}

.menuColumn ul a {
  text-decoration: none;
  color: #000;
}

.menuColumn ul li {
  padding-bottom: 10px;
}

.menuColumn ul li a{
  padding-left: 20px;
  width: 100%;
  display: block;
}

.menuColumn ul li.subMenuList ul li a {
  padding-left: 0px !important;
  width: 100%;
  display: block;
}

.my-table, .my-table tr, .my-table td, .my-table th{
  border: 1px solid #ccc;
  padding-left: 20px;
}

.my-table th{
  background-color: #6c6969;
  color: #FFF;
}

.quizOptions{
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.headerMenu {
  /* background-color: rgb(72, 72, 178); */
  padding: 10px;
  padding-left: 20px;
  /* text-align: center; */
  /* color: #FFF; */
  font-size: 22px;
  font-weight: 500;
}

.headerMainMenu{
  background: #080612;
  color: #fff;
}

.activeMenu, .activeSubMenu {
  background: #4848B2;
}


.activeMenu a, .activeSubMenu a span {
  color: #FFF !important;
  /* font-weight: bold; */
}

.headerAdSpace {
  height: auto;
}

.featureClass {
  background-color: #e1f1ff;
  padding: 20px;
  border-radius: 16px;
}

.featureClass ul {
  list-style: decimal;
}

.btn-primary {
  background: #4848B2;
  color: #FFF;
  border: 1px solid #4848B2;
}

a.linkPage {
  color: #4848B2 !important;
}

.codePalateBox {
  background: #e5e3e3;
  border-radius: 10px;
  word-wrap: break-word;
}

.codePalateBox .heading {
  background: #2F2F2F;
  padding: 0;
  border-radius: 10px 10px 0 0;
  color: #FFF;
  font-weight: lighter;
}

.codePalate {
  /* color: #FFF; */
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.outputPalateBox{
  background: #FFF;
  color: #000;
  border: 1px solid #000;
  padding: 10px;
}

.outputPalate{
  color: #000;
  padding: 0;
}

.codePalate p {
  margin: 0;
}
table a{
  text-decoration: none;
}
table p{
  margin-top: 0;
  margin-bottom: 0;
}

.text-white{
  color:#FFF;
  padding-right: 20px;
}

.no-decoration{
  text-decoration: none;
  color:#FFF;
}

.dropdown-link-menu, .dropdown-link-menu:hover{
  background: none;
  border: none;
}
.activeMenu ul a{
  color: #000 !important;
}

.activeMenu span, .activeMenu span:hover{
  color: #FFF !important;
}

.menuColumn ul span,  .menuColumn ul span:hover{
  color: #000;
}

.menuColumn ul a {
  text-decoration: none;
  color: #000;
}

.background-white{
  background: #FFF;
}

.ml-30{
  margin-left: 30px !important;
}
.ml-60{
  margin-left: 60px !important;
}
.ml-90{
  margin-left: 90px !important;
}

.ml-120{
  margin-left: 120px !important;
}

.ml-150{
  margin-left: 150px !important;
}

.ml-180{
  margin-left: 180px !important;
}

.onetime:before{
  content: '✓';
}

.codePalateBox .copyCode {
  padding-right: 18px;
  font-size: 13px;
  color: #FFF;
  font-weight: lighter;
}

.color-yellow {
  color: #E2950C;
}

.color-blue {
  color: #2E95D3
}

.color-green {
  color: #00A659;
}

.color-pink {
  color: #DF3079;
}

.color-grey {
  color: #868181;
}

.color-red {
  color: #F22C27
}

.codeSample {
  font-weight: 100;
}

.copyCode button {
  color: #FFF;
}

.fontStyle{
  font-size:20px;
  font-weight:bold;
  font-family:Consolas, 'Courier New', Courier, monospace;
}

.transparentBackground {
  line-height:80px;
  color:white;
  font-size:20px;
  font-weight:bold;
  font-family:Consolas, 'Courier New', Courier, monospace;
  background-image:url(../public/assets/img_bg_transparent.gif);
  background-repeat:repeat;
  background-position:top left;
  background-color:#ffffff;
}

.bannerPart {
  background-image: url(../public/assets/group-background.png), url(../public/assets/background.webp);
  background-repeat: no-repeat, repeat;
  background-position: 0% 100%, center center
    /*left top*/
  ;
  padding-top: 90px;
  padding-bottom: 90px;
  height: 60%;
  background-size: 50%, cover;
  text-align: center;
  color: #FFF;
}

.startJourney {
  background-image: url(../public/assets/your-journey-starts-here.png);
  background-repeat: no-repeat;
  background-position: 3% 100%;
  background-size: 28%;
  background-position-y: center;
}

.bannerText {
  color: #FFC0C7 !important;
  margin-right: 20px;
  z-index: 1;
  font-size: 36px !important;
  vertical-align: bottom
}

.bannercontent {
  padding-left: 20%;
  padding-right: 20%;
}

.codeh1 {
  font-size: 70px;
  font-weight: 700;
}

.codeh2 {
  font-size: 50px;
  font-weight: 700;
}

.codeh3 {
  color: #FFF4A3;
  font-weight: 700;
  margin-top: 30px !important;
  font-size: 23px;
}

.codeh4 {
  color: white;
  font-size: 23px;
  font-weight: 700;
  margin-top: 30px !important;
}

.codeh4 a {
  color: white;
}

.bannerPart .inputText {
  padding: 10px;
  font-size: 17px;
  border: 1px solid #282A35;
  border-radius: 25px;
  float: left;
  width: 80%;
  padding-left: 25px;
  background: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  border-right: 1px solid #04AA6D;
  outline: 0;
}

.bannerPart button {
  border-radius: 25px;
  float: left;
  width: 20%;
  padding: 10px;
  background: rgb(126 126 222);
  color: white;
  font-size: 17px;
  border: 1px solid #282A35;
  border-left: none;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.notFoundContainer {
  height: 100vh;
  position: relative;
}

.notFoundRow {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30%;
}

.section-shadow {
  border: 2px solid rgb(8, 6, 18);
  border-radius: 50px;
  box-shadow: 5px 18px rgb(8, 6, 18);
}

.aspirant-learn-button {
  padding: 10px;
  margin-right: 25px;
  width: 100%;
  height: 100px;
  border-radius: 25px;
  background: rgb(175 175 217);
  color: rgb(8, 6, 18);
  position: relative;
  border: 1px solid rgb(175 175 217);
  text-align: start;
}

.aspirant-learn-button a {
  text-decoration: none;
  color: inherit;
  margin: 0;
  font-weight: 400;
  margin-left: 10px;
  font-size: 22px;
}

.aspirant-learn-button span {
  margin-left: 10px;
}

.copyright {
  height: 15px;
  text-align: center;
}

.headerSocialPart{
  margin-top: 35px;
}

.socialPart span {
  padding: 10px;
  font-size: 30px;
}

.headerSocialPart span {
  padding: 10px;
  font-size: 30px;
}

.my-menu-dropdown a {
  display: inline-block;
  margin-right: 30px;
  width: auto;
}

.hiddenInLarge {
  display: none;
}

.hiddenInSmall {
  display: block;
}

.menuBar {
  display: none;
}

/* .menuColumn{
  overflow-y: scroll;
  height: calc(-110px + 100vh);
  width: 350px;
  float: left;
  position: absolute;
} */

.dataColumn {
  overflow-y: scroll;
  height: calc(-110px + 100vh);
  margin-left: 350px;
  width: calc(100vw - 265px);
  float: right;
}

.menuColumnMobile.menuColumn {
  display: block;
  position: absolute;
  width: 250px;
  z-index: 99999;
}

.mainBody::-webkit-scrollbar {
  display: none;

}

.mainBody {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}

.rightPart::-webkit-scrollbar, .middlePart::-webkit-scrollbar,
.menuColumn::-webkit-scrollbar {
  display: none;
}

h6{
  font-weight: bold;
}

.mainBody {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pl-25 {
  padding-left: 25px;
}

.dropdown-item.active {
  background-color: #c8c8ea;
  color: rgb(8, 6, 18);
  border: 2px solid rgb(8, 6, 18);
  padding-left: 10px;
  padding-right: 10px;
}
.noUnderline{
  text-decoration: none;
}
.backgroundNone{
  background: none;
}

.borderNone{
  border: none;
}

.myImage img{
  width:70%;
}

.boxmodel .margin {
  background: #f1f1f1;
  padding: 45px;
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #bbb;
  transition: all .25s ease-in-out;
  color: black;
}

.margin:before {
  content: "Margin";
  font-size: 1.4em;
  position: absolute;
  left: 0;
  top: 1.8%;
  width: 100%;
  text-align: center;
}

.border {
  padding: 45px;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: lightblue !important;
}

.border:before {
  content: "Border";
  font-size: 1.4em;
  color: black;
  position: absolute;
  left: 0;
  top: 2.5%;
  width: 100%;
  text-align: center;
}

.padding {
  color: black;
  padding: 45px;
  width: 100%;
  height: 100%;
  position: relative;
  background: #f1f1f1;
}

.padding:before {
  content: "Padding";
  font-size: 1.4em;
  position: absolute;
  left: 0.5%;
  top: 3.7%;
  width: 100%;
  text-align: center;
}

.content {
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  border: 2px dashed #bbb;
}

.content:before {
  content: "Content";
  font-size: 1.4em;
  display: block;
  text-align: center;
  line-height: 3.5;
}

.outline {
  background: #9494e9;
  padding: 45px;
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #9494e9;
  transition: all .25s ease-in-out;
}

.outline:before {
  content: "Outline";
  font-size: 1.4em;
  position: absolute;
  left: 0;
  top: 1.8%;
  width: 100%;
  text-align: center;
}

.background-grey{
  background:rgb(226 222 222);
  /* padding: 5px;
  font-family: serif; */
  border-radius: .25rem;
  font-size: .875em;
  /* font-weight: 500; */
  padding: .15rem .3rem;
}

.operator::marker {
  font-size: 25px;
}

abbr[title]{
  text-decoration: none;
}

.middlePart{
    overflow-y: scroll;
    height: calc(-110px + 100vh);
    width: calc(60% - 80px);
    margin: 20px 20px 20px 0px;
}

.contentSection{
  padding-top: 30px;
  margin-top: 30px;
}

.contentPart .contentSection{
  background: #fff;

}

.rightPart{
  overflow-y: scroll;
  height: calc(100vh - 110px);
  width: 20%;
  margin: 20px;
  margin-left: 0px;
}

.menuColumn{
  overflow-y: scroll;
  height: calc(100vh - 110px);
  width: 20%;
  margin: 20px;
}

.operator h5{
  font-size: 20px !important;
}

.myBorderClass tbody, .myBorderClass td, .myBorderClass tfoot, .myBorderClass th, .myBorderClass thead, .myBorderClass tr{
  border-width: initial !important;
  border: 1px solid #aaacae;
}

.noDecor{
  text-decoration: none;
  color: inherit;
}

.featureList li{
  margin-top: 20px;
}

.folderStructure p{
  margin: 0;
  line-height: normal;
  font-size: 18px;
}

.mt-0{
  margin-top: 0;
}

.mb-0{
  margin-bottom: 0;
}

.mr-5{
  margin-right: 5%;
}


.eachCareer{
  border: 1px solid #CCC;
  padding: 0 40px;
  width: 47%;
}

.marginRight5{
  margin-right: 5%;
}

.trendingCareer{
  margin-top: 30px;
  margin-bottom: 30px;
}

.latestUpdates .aspirant-learn-button, .trendingCareer .aspirant-learn-button
{
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannercontent {
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.bannerPart .bannercontent{
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .startJourney{
    background: none;
  }

  .bannerPart {
    background-image: url(../public/assets/background.webp);
    background-repeat: repeat;
    background-position: center center
      /*left top*/
    ;
    padding-top: 90px;
    padding-bottom: 90px;
    height: 60%;
    background-size: cover;
    text-align: center;
    color: #FFF;
  }

  .bannerPart .bannercontent{
    text-align: center;
  }
}

@media screen and (max-width: 992px) {

  .bannercontent {
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  
  }
  
  .eachCareer{
    padding: 10px;
  }

  /* .aspirant-learn-button span{
    display: none;
    position: absolute;
  } */
  

  .menuColumn{
    width: 25%;
  }

  .middlePart{
    width:calc(75% - 60px);
  }

  .rightPart{
    display: none;
  }

  .dataColumn {
    margin-left: 0 !important;
  }

  .topHeader-2 div {
    width: 100%;
  }

  .language-menu {
    height: 45px;
    overflow: hidden;
    width: 100%;
  }

  .aspirant-learn-button a {
    left: 17%;
  }

  .topHeader-2 {
    padding: 0px 8%;
  }

  .topHeader {
    padding: 0 5%;
  }

  .bannerPart {
    background-image: url(../public/assets/background.webp);
    background-repeat: repeat;
    background-position: center center
      /*left top*/
    ;
    padding-top: 90px;
    padding-bottom: 90px;
    height: 60%;
    background-size: cover;
    text-align: center;
    color: #FFF;
  }

  .codeh4 {
    margin-top: 50px !important;
  }

  .footerMenu h4 {
    font-size: 20px;
  }

  .footerMenu ul {
    font-size: 18px;
  }

  .myImage img{
    width:100%;
  }

  .startJourney{
    background: none;
  }


}



@media screen and (max-width: 600px) {


  .startLearning{
    padding: 20px;
  }

  .eachCareer{
    width: 100%;
    padding: 10px;
  }

  .marginRight5{
    margin: 0;
  }

  .middlePart{
    width:100%;
  }


  .menuColumn {
    display: none;
  }

  .menuBar {
    display: inline;
    margin-right: 20px;
  }

  .topHeader-2 {
    padding: 0px 8%;
    margin-top: 5%;
  }

  .hiddenInLarge {
    display: block;
  }

  .headerSocialPart {
    position: absolute;
    display: none
  }

  .codeh1 {
    font-size: 50px;
  }

  .bannercontent {
    padding-left: 10%;
    padding-right: 10%;
  }

  .hiddenInSmall {
    display: none;
  }

  .aboutUs .codeh3 {
    margin-bottom: 0px;
    padding: 0 20px;
  }

  .latestUpdates .aspirant-learn-button, .techSkillContainer
  {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .newUpdate{
    border: 0;
    box-shadow: none;
    padding: 25px;
  }

  .eachCareer{
    width: calc(100% - 60px);
    margin: 30px;
  }

  .eachCareer .eachTech {
    padding: 0 40px;
  }
}

@media screen and (max-width: 320px) {

  .eachCareer{
    width: calc(100% - 60px);
    margin: 30px;
  }

  .eachCareer .eachTech {
    padding: 0 40px;
  }

  .codeh2 {
    font-size: 40px;
    font-weight: 700;
}

  .newUpdate{
    border: 0;
    box-shadow: none;
  }

  .startLearning{
    padding: 10px;
  }

  .menuColumn {
    display: none;
  }

  .socialPart span {
    padding: 10px;
    font-size: 20px;
  }

  .footerMenu {
    font-size: 20px;
  }

  .showMenu {
    width: 300px;
    position: absolute;
  }

  .aboutUs p{
    padding: 0;
  }

  .aboutUs .codeh3 {
    margin-bottom: 0px;
    padding: 0 20px;
  }

  .latestUpdates .aspirant-learn-button
  {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .newUpdate{
    padding: 15px;
  }


}